import $ from 'jquery';

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validateEmailAndShowMsg() {
    const msg = $('#emailMsg');
    const email = $('#emailAddress').val();
    msg.text('');
    if (validateEmail(email)) {
        return true;
    } else {
        msg.text('Die E-Mail Adresse ist nicht valide: ' + email);
        msg.css('color', 'red');
        return false;
    }
}

function validateFields() {
    const fields = '#firstName, #lastName, #emailAddress, #privacyCheck';
    $(fields).change(function() {
        if (
            $('#firstName').val() !== '' &&
            $('#lastName').val() !== '' &&
            $('#emailAddress').val() !== '' &&
            validateEmail($('#emailAddress').val()) &&
            $('#privacyCheck').prop('checked')
        ) {
            $('#btnSubmit').attr('disabled', false);
        } else {
            $('#btnSubmit').attr('disabled', true);
        }
    });
    $('#emailAddress').change(function() {
        validateEmailAndShowMsg();
    });
}

function getFormDataAsJson() {
    let object = {};
    const data = new FormData($('#contactForm')[0]);
    data.forEach((value, key) => (object[key] = value));
    return JSON.stringify(object);
}

function getSuccess(btnSubmit) {
    return function() {
        btnSubmit.prop('disabled', false);
        if ($('#attachment').val() !== '' && $('#attachment').val()) {
            $('.answer-download').show();
        } else {
            $('.answer').show();
        }
        $('#contactForm').hide();
    };
}

function sendData(url, json, btnSubmit) {
    $.ajax({
        type: 'POST',
        url: url + '/.rest/form/contact',
        data: json,
        contentType: 'application/json',
        cache: false,
        timeout: 800000,
        success: getSuccess(btnSubmit),
        error: function(request, status, error) {
            btnSubmit.prop('disabled', false);
            if (request.responseText === 'Captcha') {
                const msg = $('#captchaMsg');
                const captchaAnswer = document.getElementById('captchaAnswer');
                msg.text('Das Captcha war Falsch');
                msg.css('color', 'red');
                captchaAnswer.value = '';
            }
        },
    });
}

function changeLabel() {
    const msg = $('#captchaMsg');
    msg.text('');
}

function updateCaptcha() {
    var img = $('#captchaImg');
    const url = document.getElementById('url').value;
    img.attr('src', url + '/.rest/captcha.png?' + new Date().getTime());
}

$(document).ready(function() {
    updateCaptcha();
    document.getElementById('captchaAnswer').addEventListener('input', changeLabel);
    document.getElementById('captchaReload').addEventListener('click', updateCaptcha);
    validateFields();
    const url = document.getElementById('url').value;
    const btnSubmit = $('#btnSubmit').attr('disabled', true);
    btnSubmit.click(function(event) {
        event.preventDefault();
        const json = getFormDataAsJson();
        btnSubmit.prop('disabled', true);
        sendData(url, json, btnSubmit);
    });
});
